import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import XaqtHeroSection from '../sections/caseStudies/Xaqt/XaqtHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import XaqtSection1 from '../sections/caseStudies/Xaqt/XaqtSection1';
import XaqtSection2 from '../sections/caseStudies/Xaqt/XaqtSection2';
import XaqtSection3 from '../sections/caseStudies/Xaqt/XaqtSection3';
import XaqtSection4 from '../sections/caseStudies/Xaqt/XaqtSection4';
import XaqtSection5 from '../sections/caseStudies/Xaqt/XaqtSection5';
import XaqtSection6 from '../sections/caseStudies/Xaqt/XaqtSection6';
import XaqtSection7 from '../sections/caseStudies/Xaqt/XaqtSection7';
import TestimonialSection from '../sections/TestimonialSection';
import XaqtSection8 from '../sections/caseStudies/Xaqt/XaqtSection8';

const Xaqt = ({data}) => {
    const mainData = data.allWpPage.nodes[0].caseStudyXaqtPageACF;
    const sectionRef = useRef();
    const [bgTransition, setBgTransition] = useState('');
  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger)
  //   setTimeout(() => {
  //     gsap.set(sectionRef.current, {
  //       scrollTrigger: {
  //         trigger: '.xaqt-case-study .section-5',
  //         start: "top 60%",
  //         end: "bottom 60%",
  //         ease: "ease",
  //         scrub: true,
  //         markers: false,
  //         onEnter: () => setBgTransition('xaqt-bg'),
  //         onLeave: () => setBgTransition(''),
  //         onEnterBack: () => setBgTransition('xaqt-bg'),
  //         onLeaveBack: () => setBgTransition(''),
  //       }
  //     });
  //   }, 1000);
  // }, []);
  return(
      <Layout headerClass="white-header">
          <Seo title="Xaqt" />
          <div className={"xaqt-case-study page-wrapper " + bgTransition} id="xaqt-casestudy-page-wrapper" ref={sectionRef}>
            <XaqtHeroSection 
                preTitle={parse(mainData.xaqtHeroSectionPretitle)}
                mainTitle={parse(mainData.xaqtHeroSectionTitle)}
                image1x={
                    {
                      sourceUrl: mainData.xaqtHeroSectionImage1x.sourceUrl,
                      altText: mainData.xaqtHeroSectionImage1x.altText,
                      width: mainData.xaqtHeroSectionImage1x.width,
                    }
                }
                image2x={
                  {
                    sourceUrl: mainData.xaqtHeroSectionImage2x.sourceUrl,
                    altText: mainData.xaqtHeroSectionImage2x.altText,
                    width: mainData.xaqtHeroSectionImage2x.width,
                  }
                }
                mobileImage={
                  {
                    sourceUrl: mainData.xaqtHeroSectionMobileImage.sourceUrl,
                    altText: mainData.xaqtHeroSectionMobileImage.altText,
                    width: mainData.xaqtHeroSectionMobileImage.width,
                  }
                }
            />
            <ChallengesSection 
                companyInfoList={mainData.xaqtChallengeSectionCompanyInfoList}
                challengesList={mainData.xaqtChallengeSectionChallengesList}
            />
            <XaqtSection1 
                imageslist={mainData.xaqtSection1ImageList}
            />
            <XaqtSection2 
              mainTitle={parse(mainData.xaqtSection2Title)}
              mainDescription={parse(mainData.xaqtSection2Description)}
              imageslist={mainData.xaqtSection2ImageList}
            />
            <XaqtSection3 
                mainTitle={parse(mainData.xaqtSection3Title)}
                mainDescription={parse(mainData.xaqtSection3Description)}
                image1x={
                  {
                    sourceUrl: mainData.xaqtSection3Image11x.sourceUrl,
                    altText: mainData.xaqtSection3Image11x.altText,
                    width: mainData.xaqtSection3Image11x.width,
                  }
                }
                image2x={
                  {
                    sourceUrl: mainData.xaqtSection3Image12x.sourceUrl,
                    altText: mainData.xaqtSection3Image12x.altText,
                    width: mainData.xaqtSection3Image12x.width,
                  }
                }
            />
            <XaqtSection4 
              mainTitle={parse(mainData.xaqtSection4Title)}
              mainDescription={parse(mainData.xaqtSection4Description)}
              imageslist={mainData.xaqtSection4ImageList}
            />
            <XaqtSection5 
                mainTitle={parse(mainData.xaqtSection5Title)}
                mainDescription={parse(mainData.xaqtSection5Description)}
                imageslist={mainData.xaqtSection5ImageList}
                image11x={
                  {
                    sourceUrl: mainData.xaqtSection5Image11x.sourceUrl,
                    altText: mainData.xaqtSection5Image11x.altText,
                    width: mainData.xaqtSection5Image11x.width,
                  }
                }
                image12x={
                  {
                    sourceUrl: mainData.xaqtSection5Image12x.sourceUrl,
                    altText: mainData.xaqtSection5Image12x.altText,
                    width: mainData.xaqtSection5Image12x.width,
                  }
                }
                mainTitle2={parse(mainData.xaqtSection5Title2)}
                image21x={
                  {
                    sourceUrl: mainData.xaqtSection5Image21x.sourceUrl,
                    altText: mainData.xaqtSection5Image21x.altText,
                    width: mainData.xaqtSection5Image21x.width,
                  }
                }
                image22x={
                  {
                    sourceUrl: mainData.xaqtSection5Image22x.sourceUrl,
                    altText: mainData.xaqtSection5Image22x.altText,
                    width: mainData.xaqtSection5Image22x.width,
                  }
                }
            />
            <XaqtSection6 
              mainTitle={parse(mainData.xaqtSection6Title)}
              mainDescription={parse(mainData.xaqtSection6Description)}
              image1x={
                {
                  sourceUrl: mainData.xaqtSection6Image1x.sourceUrl,
                  altText: mainData.xaqtSection6Image1x.altText,
                  width: mainData.xaqtSection6Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.xaqtSection6Image2x.sourceUrl,
                  altText: mainData.xaqtSection6Image2x.altText,
                  width: mainData.xaqtSection6Image2x.width,
                }
              }
            />
            <XaqtSection7 
              mainTitle={parse(mainData.xaqtSection7Title)}
              imageslist={mainData.xaqtSection7ImageList}
            />
            <XaqtSection8 
              mainTitle={parse(mainData.xaqtSection8Title)}
              mainDescription={parse(mainData.xaqtSection8Description)}
              image1x={
                {
                  sourceUrl: mainData.xaqtSection8Image1x.sourceUrl,
                  altText: mainData.xaqtSection8Image1x.altText,
                  width: mainData.xaqtSection8Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.xaqtSection8Image2x.sourceUrl,
                  altText: mainData.xaqtSection8Image2x.altText,
                  width: mainData.xaqtSection8Image2x.width,
                }
              }
              mainTitle2={parse(mainData.xaqtSection8Title2)}
              imageslist={mainData.xaqtSection8ImageList}
            />
            {/* <TestimonialSection
                extraClasses=""
                mainTitle={parse(mainData.xaqtTestimonialSectionTitle)}
                message={parse(mainData.xaqtTestimonialSectionDescription)}
                name={parse(mainData.xaqtTestimonialSectionName)}
                designation={parse(mainData.xaqtTestimonialSectionDesignation)}
                headshot1x={
                  {
                    sourceUrl: mainData.xaqtTestimonialSectionHeadshot1x.sourceUrl,
                    altText: mainData.xaqtTestimonialSectionHeadshot1x.altText,
                    width: mainData.xaqtTestimonialSectionHeadshot1x.width,
                  }
                }
                headshot2x={
                  {
                    sourceUrl: mainData.xaqtTestimonialSectionHeadshot2x.sourceUrl,
                    altText: mainData.xaqtTestimonialSectionHeadshot2x.altText,
                    width: mainData.xaqtTestimonialSectionHeadshot2x.width,
                  }
                }
                logo={
                  {
                    sourceUrl: mainData.xaqtTestimonialSectionLogo.sourceUrl,
                    altText: mainData.xaqtTestimonialSectionLogo.altText,
                    width: mainData.xaqtTestimonialSectionLogo.width,
                  }
                }
            /> */}
          </div>
      </Layout>
  )
}

export const XaqtQuery = graphql`
{
    allWpPage(filter: {slug: {eq: "xaqt"}}) {
        nodes {
          caseStudyXaqtPageACF {
            xaqtHeroSectionPretitle
            xaqtHeroSectionTitle
            xaqtHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            xaqtHeroSectionImage2x {
              altText
              width
              sourceUrl
            }
            xaqtHeroSectionMobileImage {
              altText
              sourceUrl
              width
            }
            xaqtChallengeSectionChallengesList {
                title
                description
            }
            xaqtChallengeSectionCompanyInfoList {
              title
              description
            }
            xaqtSection1ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            xaqtSection2Title
            xaqtSection2Description
            xaqtSection2ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            xaqtSection3Title
            xaqtSection3Description
            xaqtSection3Image11x {
              altText
              sourceUrl
              width
            }
            xaqtSection3Image12x {
              altText
              sourceUrl
              width
            }
            xaqtSection4Title
            xaqtSection4Description
            xaqtSection4ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            xaqtSection5Title
            xaqtSection5Description
            xaqtSection5ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            xaqtSection5Image11x {
              altText
              sourceUrl
              width
            }
            xaqtSection5Image12x {
              altText
              sourceUrl
              width
            }
            xaqtSection5Title2
            xaqtSection5Image21x {
              altText
              sourceUrl
              width
            }
            xaqtSection5Image22x {
              altText
              sourceUrl
              width
            }
            xaqtSection6Title
            xaqtSection6Description
            xaqtSection6Image1x {
              altText
              sourceUrl
              width
            }
            xaqtSection6Image2x {
              altText
              sourceUrl
              width
            }
            xaqtSection7Title
            xaqtSection7ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            xaqtSection8Title
            xaqtSection8Description
            xaqtSection8Image1x {
              altText
              sourceUrl
              width
            }
            xaqtSection8Image2x {
              altText
              sourceUrl
              width
            }
            xaqtSection8Title2
            xaqtSection8ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            xaqtTestimonialSectionTitle
            xaqtTestimonialSectionDescription
            xaqtTestimonialSectionName
            xaqtTestimonialSectionDesignation
            xaqtTestimonialSectionHeadshot1x {
              altText
              sourceUrl
              width
            }
            xaqtTestimonialSectionHeadshot2x {
              altText
              sourceUrl
              width
            }
            xaqtTestimonialSectionLogo {
              altText
              sourceUrl
              width
            }
          }
        }
      }
}

`

export default Xaqt;