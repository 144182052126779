import * as React from "react"
import shortid from  "shortid";

const XaqtSection1 = (props) => {
    return (
        <section className="section-1">
            <div className="container">
                <div className="image-block">
                    <div className="row">
                        {props.imageslist?.map((object, i) => {
                            return (
                                <div className="col-lg-12" key={shortid.generate()}>
                                    <div className="item">
                                        <img
                                            src={object.image1x?.sourceUrl}
                                            srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                            width={object.image1x?.width}
                                            alt={object.image1x?.altText}
                                        />
                                    </div>
                                </div>     
                            )
                        })}
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default XaqtSection1;